$mainTextColor: #333333;
$mainBackgroundColor: #ffffff;
$mainBackgroundSecondaryColor: $mainTextColor;
$disabledColor: #dddddd;
$chipBackgroundColor: rgb(207 207 207 / 45%);

/* Primary and Secondary accent color for your app */
$primaryColor: #DF002C;
$primaryTextColor: #ffffff;
$secondaryColor: #770017;
$tertiaryColor: #B60024;
/* =============================================== */

$infoTextColor: #0072bb;
$infoBackgroundColor: #07CCD6;

$successTextColor: #006e57;
$successBackgroundColor: #a9e2da;

$warningTextColor: #a99700;
$warningBackgroundColor: #f6f2bd;

$errorTextColor: #ca1829;
$errorBackgroundColor: #ffccd3;
