$textSize: 16px;
$h1Size: 50px;
$h2Size: $h1Size * 0.7;
$h3Size: $h2Size * 0.7;
$h4Size: $h3Size * 0.7;
$h5Size: $textSize;

$padding: 10px;
$paddingSidebar: 15px 5px 15px 10px;
$paddingNavbar: 10px;
$paddingContent: 20px;
$appContentPadding: 20px;
$margin: 20px;
$iconMargin: 10px;
$formMargin: 10px 0;
$listMargin: 10px 0;

$tableActionIconSize: 24px;
