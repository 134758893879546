@import "palette";
@import "sizes";
@import "forms";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer {
  display: flex;
  flex-grow: 1;
}

h1, h2, h3, h4, h5, h6, p, span, input {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif!important;
}

h1, h2, h3, h4, h5 {
  color: $mainTextColor;
  display: block;
}

h1 { font-size: $h1Size; }
h2 { font-size: $h2Size; }
h3 { font-size: $h3Size; }
h4 { font-size: $h4Size; }
h5 { font-size: $h5Size; }

p {
  color: $mainTextColor;
}

span {
  display: inline-block;
}

small {
  display: inline-block;
  color: $mainTextColor;
  font-size: $textSize * 0.8;
}

a {
  display: inline-block;
  color: $primaryColor;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;

  :hover { opacity: 0.7; }
  :visited { color: $tertiaryColor; }
}

code {
  background: $mainBackgroundSecondaryColor;
  font-family: 'monospace';
  color: $mainTextColor;
  padding: $padding;
}

ul:not(.jasmine-suite) {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: $listMargin;
  }
}

i.fa {
  display: inline-block;
  padding: 0 $padding;
}

i.fa-solid + span {
  margin-left: $iconMargin;
}
span + i.fa-solid {
  margin-left: $iconMargin;
}

.table-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .table-header-text{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    i {
      font-size: 20px;
      padding-right: 10px;
    }
  }
}

.table-search-form-container {
  display: flex;
  flex-direction: row-reverse!important;
  width: 50%;
}

/*Material input icon*/
/*make suffix not hug right side of input*/
.mat-mdc-form-field-icon-suffix{
  padding-right: $padding!important;
}

.transparent { border: none; outline: none; background: transparent; }

.modal-dialog-actions{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $paddingContent!important;
}

.table-action-delete {
  color: $errorTextColor;
}

.table-action {
  color: $mainTextColor;
}

/* MATERIAL INPUTS */
/* We set style based on our palette */
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: $primaryColor!important;
}

.mat-mdc-form-field:not(.mat-form-field-invalid) mat-label {
  color: $primaryColor!important;
}

/* MATERIAL BUTTONS */
/* We set style based on our palette */

button.mat-primary, a.mat-primary {
  color: $primaryTextColor!important;
  background-color: $primaryColor!important;
  cursor: pointer!important;
}
button.mat-accent, a.mat-accent {
  color: $primaryTextColor;
  background-color: $secondaryColor;
  cursor: pointer!important;
}
button.mat-alternative, a.mat-alternative {
  color: $primaryTextColor!important;
  background-color: $tertiaryColor!important;
  cursor: pointer!important;
}
button.mat-warn, a.mat-warn {
  color: $warningTextColor;
  background-color: $warningBackgroundColor;
  cursor: pointer!important;
}

button.mat-disabled, a.mat-disabled {
  color: $mainTextColor;
  background-color: $disabledColor;
  cursor: pointer!important;
}

.mat-mdc-button {
  display: inline-flex;
  cursor: pointer!important;

}

/* MATERIAL SNACKBAR */
/* We set style based on our palette */
.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: transparent!important;
  }

  &.app-notification-info {
    background-color: $infoBackgroundColor!important;
    div, button { color: $infoTextColor!important; }
  }

  &.app-notification-warning {
    background-color: $warningBackgroundColor;
    div, button { color: $warningTextColor!important; }
  }

  &.app-notification-error {
    background-color: $errorBackgroundColor;
    div, button { color: $errorTextColor!important; }
  }

  &.app-notification-success {
    background-color: $successBackgroundColor;
    div, button { color: $successTextColor!important; }
  }
}

/* MATERIAL SPINNER */
/* We set the style for the material spinner indicator */
mat-spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
  stroke: $primaryColor!important;
}

/* MATERIAL LIST */
/* We set color for all list items even inside custom select components */
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: $primaryColor!important;
}
/* Material Menu Panel */
/* We set the top and right margin from the origin point */
.mat-mdc-menu-panel:not(.table-action-menu) {
  margin-top: 15px!important;
  margin-right: 15px!important;
}

/*Material menu panel item*/
/*Added padding and increased font size*/
.mat-mdc-menu-item {
  padding-right: $paddingContent!important;
  padding-left: $paddingContent!important;
  i, span {
    font-size: $h4Size!important;
  }
}

/*Material menu panel*/
/*make panel appear from the right*/
.mat-mdc-menu-panel:not(.table-action-menu) + .cdk-overlay-connected-position-bounding-box {
  align-items: flex-end!important;
}

/*Material Paginator*/
/*Arrow color on selection*/
.mat-mdc-paginator-page-size-select.mat-focused .mat-mdc-select-arrow{
  color: $primaryColor!important;
}

/*Border on selection*/
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing
{
  border-color: $primaryColor!important;
}

/*Material selection*/

// option selected checkbox color on activated
.mat-mdc-option.mdc-list-item--selected  {
  .mat-pseudo-checkbox {
    display: none;
  }
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after{
    color: #ffffff;
  }
}
//option selected in multiple select
.mat-mdc-option-multiple.mdc-list-item--selected  {
  .mat-pseudo-checkbox {
    display: inline!important;
  }
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: $primaryColor;
  }
}
.mat-mdc-form-field-type-mat-select.mat-focused{
  .mat-mdc-form-field-required-marker::after{
    color: $primaryColor!important;
  }
}

// selection inside form field
.mat-mdc-form-field.mat-focused .mat-mdc-select .mat-mdc-select-arrow {
  color: $primaryColor!important;
}

/* MAT CHECKBOX */

//Change background color
::ng-deep .mat-mdc-checkbox-checked .mdc-checkbox{
  .mdc-checkbox__background {
    background-color: $primaryColor!important;
    //Change checkmark color
    .mdc-checkbox__checkmark {
      color: $primaryTextColor!important;
    }
  }
}

::ng-deep .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: $primaryColor!important;
}


::ng-deep .mdc-checkbox{
  .mdc-checkbox__background {
    background-color: #FFFFFF!important;
    border: 1px solid #707070!important;
    border-radius: 5px;
    opacity: 1;

    .mdc-checkbox__checkmark {
      color: black!important;
    }
  }
  //Remove ripple background color
  .mdc-checkbox__ripple {
    background-color: transparent!important;
  }
}

/*RADIO-BUTTON*/

/* MAT RADIO BUTTON */
.mat-mdc-radio-checked {
  .mdc-radio__outer-circle, .mdc-radio__inner-circle {
    border-color: $primaryColor!important;
  }
}

.mat-radio-ripple {
  display: none!important;
}

/* MAT CALENDAR */
.mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  background-color: $primaryColor!important;
  color: $primaryTextColor!important;
}

.mat-calendar-body-active .mat-calendar-body-cell-content {
  background-color: $secondaryColor!important;
  color: $primaryTextColor!important;
}

.mat-datepicker-toggle-active {
  color: $primaryColor!important;
}
